import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Banner from "./banner";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Loader from "../components/Loader";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import {checkData} from "../../helper";
import Episodesection from "./episodesection";
import Timestamp from "./timestamp";
import Mention from "./mention";

function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);


    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/podcast-listing/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);
    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>

                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Podcast" link3={checkData(userData, 'name')}/>
                    <Banner data={userData}/>
                    <Episodesection data={userData}/>
                    <Timestamp data={userData}/>
                    <Mention data={userData}/>

                </>
            }
        </Layout>
    );
}

export default Index;